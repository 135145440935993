





































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import TelaGenerica from '@/components/layout/TelaGenerica.vue'
import ListagemDeProdutosPorCards from './ListagemDeProdutosPorCards.vue'
import ListagemDeProdutosPorLista from './ListagemDeProdutosPorLista.vue'
import { FiltroDeBuscaDeProdutos, Page, ProdutoProjection, TipoDeProduto } from '@/models'
import { ProdutoModule } from '@/store/vuex/produto/ProdutoStore'
import CheckboxButton from '@/components/ui/CheckboxButton.vue'
import MenuDeFiltrosDeProduto from '@/components/produto/MenuDeFiltrosDeProduto.vue'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { Pageable } from '@/models/Pageable'
import axios, { CancelTokenSource } from 'axios'
import { FindProdutoUseCase } from '@/usecases'

const ULTIMA_LISTAGEM_DE_PRODUTOS = 'ULTIMA_LISTAGEM_DE_PRODUTOS'

type TipoDaListagem = 'grade' | 'lista'

@Component({
	components: {
		TelaGenerica,
		ListagemDeProdutosPorCards,
		ListagemDeProdutosPorLista,
		MenuDeFiltrosDeProduto,
		CheckboxButton,
	},
})
export default class TelaDeProdutos extends Vue {
	busca = ''
	tipoDaListagem: TipoDaListagem = localStorage.getItem(ULTIMA_LISTAGEM_DE_PRODUTOS) as TipoDaListagem || 'grade'

	@Watch('tipoDaListagem')
	onChangeTipoDaListagem(tipo: TipoDaListagem) {
		localStorage.setItem(ULTIMA_LISTAGEM_DE_PRODUTOS, tipo)
	}

	selecionados: string[] = []
	carregando = true
	todosForamSelecionados = false
	usarTodosOsFiltrados = false

	pagina: Page<ProdutoProjection> | null = null
	filtroAplicado: FiltroDeBuscaDeProdutos = {}
	cancelToken: CancelTokenSource | null = null
	findUseCase = new FindProdutoUseCase()

	created() {
		this.buscar()
	}

	filtrar(filtros: FiltroDeBuscaDeProdutos = {}, paginavel: Pageable = {}) {
		this.filtroAplicado = {
			...filtros,
			nome: this.busca || undefined,
		}
		this.buscar(paginavel)
	}

	async buscar(paginavel: Pageable = {page: 0, size: 40}) {
		try {
			this.limparSelecao()
			this.carregando = true
			if (this.cancelToken) this.cancelToken.cancel()
			this.cancelToken = axios.CancelToken.source()
			
			const params = {
				...this.filtroAplicado,
				tipos: ['Padrão', 'Com Variantes', 'Composto'] as TipoDeProduto[],
				...paginavel,
			}

			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}

			this.pagina = await this.findUseCase.findOtimizado(params, axiosConfig)
			ProdutoModule.setProdutosOtimizado(this.pagina.content)
		} catch (error) {
			if (axios.isCancel(error)) return
			this.pagina = null
			AlertModule.setError(error)	
		} finally {
			this.carregando = false
		}
	}

	selecionarTodos() {
		this.selecionados = this.produtosOrdenados.map(
			({ id }) => id,
		)
		this.$nextTick(() => {
			if (!this.pagina) return
			if (this.selecionados.length < this.pagina.totalElements)
				this.todosForamSelecionados = true
		})
	}

	@Watch('selecionados')
	onChangeSelecionados() {
		this.todosForamSelecionados = false
		this.usarTodosOsFiltrados = false
	}

	limparSelecao() {
		this.selecionados = []
		this.todosForamSelecionados = false
		this.usarTodosOsFiltrados = false
	}

	setBusca() {
		this.filtroAplicado.nome = this.busca
		this.buscar()
	}

	get abaLateral() {
		return this.$vuetify.breakpoint.xs || this.$route.name !== 'Produtos'
	}

	get produtosOrdenados() {
		return ProdutoModule.produtosOrdenados
	}

	get componenteDaListagem() {
		return this.tipoDaListagem === 'grade'
			? 'ListagemDeProdutosPorCards'
			: 'ListagemDeProdutosPorLista'
	}
}

import { Module, VuexModule, Mutation, getModule } from 'vuex-module-decorators'
import { Produto, ProdutoProjection, ProdutosAgrupadasPorCategoria } from '@/models'
import store from '@/store/vuex'
import { search } from '@/shareds/search-utils'

@Module({
	name: 'ProdutoStore',
	namespaced: true,
	dynamic: true,
	store,
})
export default class ProdutoStore extends VuexModule {
	termoBusca = ''
	produtoProjection: ProdutoProjection[] = []
	produtos: Produto[] = []
	produtoSelecionado: Produto | null = null

	@Mutation
	setProdutosOtimizado(produtos: ProdutoProjection[]) {
		this.produtoProjection = produtos
	}

	@Mutation
	addProduto(produto: Produto) {
		this.produtos.push(produto)
	}

	@Mutation
	atualizaProduto(produto: Produto) {
		const produtoIndex = this.produtos.findIndex(
			element => element.id === produto.id,
		)
		this.produtos.splice(produtoIndex, 1, produto)
	}

	@Mutation
	setTermoBusca(termo: string) {
		this.termoBusca = termo
	}

	@Mutation
	setProdutoSelecionado(produto: Produto) {
		this.produtoSelecionado = produto
	}

	get produtosFiltrados() {
		if (!this.termoBusca) return this.produtoProjection
		const filtered = search(this.produtoProjection, this.termoBusca)
		return filtered as ProdutoProjection[]
	}

	get termo() {
		return this.termoBusca
	}

	get produtosOrdenados() {
		return [...this.produtosFiltrados].sort(ordenarProdutoPorNome)
	}
}

export function ordenarProdutoPorNome(produtoA: ProdutoProjection, produtoB: ProdutoProjection) {
	return produtoA.nome.localeCompare(produtoB.nome)
}

export function ordenarProdutosAgrupadasPorCategoria(
	produtosPorEstadoA: ProdutosAgrupadasPorCategoria,
	produtosPorEstadoB: ProdutosAgrupadasPorCategoria,
) {
	const categoriaA = produtosPorEstadoA.categoria.toString()
	const categoriaB = produtosPorEstadoB.categoria.toString()

	return categoriaA.localeCompare(
		categoriaB,
	)
}
export const ProdutoModule = getModule(ProdutoStore)
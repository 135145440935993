






















import { Vue, Component } from 'vue-property-decorator'
import { NCM } from '@/models/fiscal/NCM'
import { formatarNcm } from '@/shareds/formatadores'
import { NCMServiceAdapter } from '@/usecases'
import AutocompleteService from '@/components/shareds/AutocompleteService.vue'
import { Inject } from 'inversify-props'

@Component({
	components: {
		AutocompleteService,
	},
})
export default class SeletorDeNcm extends Vue {
	@Inject('NCMServiceAdapter')
	private service!: NCMServiceAdapter

	itemText(ncms: (NCM | string) | (string | NCM)[]) {
		return Array.isArray(ncms)
			? ncms.map(ncm => this.displayNcm(ncm))
			:this.displayNcm(ncms)
	}

	selecaoNcm(ncm: NCM | string) {
		return typeof ncm === 'object'
			? formatarNcm(ncm.codigo)
			: formatarNcm(ncm)
	}

	displayNcm(ncm: NCM | string | null) {
		if (!ncm) return null
		return typeof ncm === 'object'
			? `${formatarNcm(ncm.codigo)} - ${ncm.descricao}`
			: formatarNcm(ncm)
	}
}

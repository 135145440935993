
















































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Produto } from '@/models'
import { VImg, VResponsive } from 'vuetify/lib'
import { isVestcasa } from '@/shareds/utils'

@Component({
	components: {
		VImg,
		VResponsive,
	},
})
export default class CardDeProduto extends Vue {
	@Prop({ type: Boolean }) selecionavel!: boolean
	@Prop({ type: Boolean }) selecionado!: boolean
	@Prop({ type: Object }) produto!: Produto
	
	cor = isVestcasa ? 'primary darken-1' : '#770bea'
}
	
